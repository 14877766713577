import hermesLogo from '../../assets/images/logo/hermes_logo.png'
import plenitudeLogo from '../../assets/images/logo/plenitude_logo.png'
import plenitudeFooterLogo from '../../assets/images/logo/plenitude_footer_logo.png'
import hermesLoginImage from '../../assets/images/logo/hermes_login_image.png'
import sccLogoFull from '../../assets/images/logo/scc_logo_full.png'
import sccLogoPreviewStart from '../../assets/images/logo/scc_logo_preview_start.png'
import sccLogoFooterFull from '../../assets/images/logo/scc_logo_footer_full.png'

const SCCConfig = (tenantUrl = '') => {
    return {
        app: {
            tenantUrl: tenantUrl,
            debugMode: false,
            appTitle: "",
            appIdentifier: "hermes-scc",
            appSupportUrl: "https://m2dtechnologies.atlassian.net/servicedesk/customer/portal/4",
            appWikiUrl: `${tenantUrl}/Hermes-Manual.pdf`, // Link to the hermes manual based on current client
            appLogoImage: {
                dark: hermesLogo,
                light: hermesLogo,
            },
            appLogoImage2: {
                dark: sccLogoFull,
                light: sccLogoFull,
            },
            appFooterLogo: {
                preview: sccLogoPreviewStart,
                full: sccLogoFooterFull
            },
            useSocials: false,
            useForgotPasswordLink: true,
            useRegisterLink: false,
            loginIllustration: hermesLoginImage,
            user_groups: [
                'planner',
                'administrator',
                'technician',
                'backoffice-manager',
                'logistician',
                'hse-manager',
                'activity-supervisor',
                'manager',
                'validator-231',
                'manager-231',
                'commercial',
                'marketing',
                'mobile-worker'
            ],
            default_lang: 'it'
        }
    }
}
const SEAConfig = (tenantUrl = '') => {
    return {
        app: {
            tenantUrl: tenantUrl,
            debugMode: false,
            appTitle: "",
            appName: "",
            appIdentifier: "hermes-sea",
            appSupportUrl: "https://m2dtechnologies.atlassian.net/servicedesk/customer/portal/4",
            appWikiUrl: `${tenantUrl}/Hermes-Manual.pdf`, // Link to the hermes manual based on current client
            appLogoImage: {
                dark: hermesLogo, light: hermesLogo,
            },
            appLogoImage2: {
                dark: plenitudeLogo, light: plenitudeLogo,
            },
            appFooterLogo: {
                preview: plenitudeFooterLogo
            },
            useSocials: false,
            useForgotPasswordLink: true,
            useRegisterLink: false,
            loginIllustration: hermesLoginImage,
            user_groups: ['planner', 'administrator', 'technician', 'backoffice-manager', 'logistician', 'hse-manager', 'activity-supervisor', 'manager', 'validator-231', 'manager-231', 'commercial', 'marketing', 'mobile-worker'],
            default_lang: 'it'
        }
    }
}
const EvolvereConfig = (tenantUrl = '') => {
    return {
        app: {
            tenantUrl: tenantUrl,
            debugMode: true,
            appTitle: "",
            appName: "",
            appIdentifier: "hermes",
            notificationEndPoint: "wss://toocbuwq80.execute-api.eu-south-1.amazonaws.com",
            appSupportUrl: "https://m2dtechnologies.atlassian.net/servicedesk/customer/portal/4",
            appWikiUrl: `${tenantUrl}/Hermes-Manual.pdf`, // Link to the hermes manual based on current client
            appLogoImage: {
                dark: hermesLogo,
                light: hermesLogo,
            },
            appLogoImage2: {
                dark: plenitudeLogo,
                light: plenitudeLogo,
            },
            appFooterLogo: {
                preview: plenitudeFooterLogo,
            },
            useSocials: false,
            useForgotPasswordLink: true,
            useRegisterLink: false,
            loginIllustration: hermesLoginImage,
            user_groups: [
                'planner',
                'administrator',
                'technician',
                'backoffice-manager',
                'logistician',
                'hse-manager',
                'activity-supervisor',
                'manager',
                'validator-231',
                'manager-231',
                'commercial',
                'marketing',
                'mobile-worker'
            ],
            default_lang: 'it'
        }
    }
}

const DemoFieldStream = (tenantUrl = '') => {
    return {
        app: {
            tenantUrl: tenantUrl,
            debugMode: true,
            appTitle: "",
            appName: "",
            appIdentifier: "fieldstreampro",
            notificationEndPoint: "wss://toocbuwq80.execute-api.eu-south-1.amazonaws.com",
            appSupportUrl: "https://m2dtechnologies.atlassian.net/servicedesk/customer/portal/4",
            appWikiUrl: `${tenantUrl}/Hermes-Manual.pdf`, // Link to the hermes manual based on current client
            appLogoImage: {
                dark: hermesLogo,
                light: hermesLogo,
            },
            useSocials: false,
            useForgotPasswordLink: true,
            useRegisterLink: false,
            loginIllustration: hermesLoginImage,
            user_groups: [
                'planner',
                'administrator',
                'technician',
                'backoffice-manager',
                'logistician',
                'hse-manager',
                'activity-supervisor',
                'manager',
                'validator-231',
                'manager-231',
                'commercial',
                'marketing',
                'mobile-worker'
            ],
            default_lang: 'it'
        }
    }
}

export {
    SEAConfig,
    EvolvereConfig,
    SCCConfig,
    DemoFieldStream
}